import { Link,useNavigate } from "react-router-dom";
import "./App.css"


import React ,{useState,useEffect} from "react";
import capa from "../../src/Asserts/banner2.png"
import capa2 from "../../src/Asserts/reembolso.png"
import capa3 from "../../src/Asserts/reembolso.png"
import modulovideo from '../../src/Asserts/video.mp4'
import modulovideo2 from '../../src/Asserts/video2.mp4'
import {auth,db} from '../firebase'
import { onSnapshot,doc } from "firebase/firestore";
import {Img} from 'react-image'

import { Carousel } from 'primereact/carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
export default function Home() {
  const [status,setStatus] = useState(false)
  const [email,setEmail] = useState('')
  const [valorP,setValorP] = useState("0")
  const [show, setShow] = useState(false);
  const [aviso, setAviso] = useState("");
  const [avise, setAvise] = useState("");
  const [controller,setController] = useState(0)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dataatual = new Date();

 const [textos,setTextos] = useState([
        {
            "name": 1,
            "image":`https://acessoareamembros.site/banner2.png`
          
          },
      
          {
            "name": 2,
        "image":`https://acessoareamembros.site/reembolso.png`
          },
        
    ])

  

async function handleCheckout(){

 /* const stripe = await loadStripe('pk_test_51OaXS4CXPtVkQR0ddKyAGEhEvTCBwoYpxCdcFvjuxhSbCgtsNVVJP74renELWHKp9IRGDudADJkJrXBIcoKdnoRH00O1y9RQO9');
  const { error } = await stripe.redirectToCheckout({
    lineItems:[
      {
        price:'price_1PRgHZCXPtVkQR0dtvhgOMe2',
        quantity:1,
      },
    ],
    mode: 'subscription',
    successUrl: 'https://viewerspy.netlify.app/sucess/{CHECKOUT_SESSION_ID}',
    cancelUrl: 'https://viewerspy.netlify.app/cancel',
    customerEmail:`${email}`
  })
 
  console.warn(error.message)
*/
/*
 if(valorP=="59"){

  window.open('https://go.perfectpay.com.br/PPU38COP9AG')


 }
 else if(valorP==139){
 
  window.open('https://go.perfectpay.com.br/PPU38COP97N')
 }
 else if(valorP==329){
 
  window.open('https://go.perfectpay.com.br/PPU38COP98N')

 }

*/
}

  
  useEffect(()=>{
    const dados = JSON.parse(localStorage.getItem("@tricretPro"))
   
   const stat = async () =>{
    const unsub = onSnapshot(doc(db, "users", `${dados.uid}`), (doc) => {
      console.log("Current data: ", doc.data());
      setEmail(doc.data().email)
   })
  }

  const avisos = async () =>{
    const avisoss = onSnapshot(doc(db, "avisos", `atualizacao`), (doc) => {
      console.log("Current data: ", doc.data());
      setAviso(doc.data().mensagem)
     
   })
  }
avisos()
  stat()
  handleShow()


 

  },[])

  window.addEventListener("load",
    function (event){
      setController(0)
    }
  )
    



  function liberamodulo(){
    if(dataatual.getDate() == 5 ){
      setController(3)
    }
  }




  return (
  
    <div className="hmCont">
      <div className="sep"></div>

      {
        controller == 0 ?
        <div style={{marginTop:100,display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center',height:'100%'}}>


        <span style={{color:'#FFF',fontWeight:'bold'}}>Todos los módulos</span>

<div style={{marginTop:20,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',marginBottom:50}}>
       


<img src={textos[0].image} width={300} style={{borderTopLeftRadius:10,borderTopRightRadius:10}}  
/>

<p style={{marginLeft:50,marginRight:50,color:'white',marginTop:10}}>Aprende cómo reducir el brillo de tu celular.</p>
      
      
        <button onClick={()=>setController(1)} style={{border:'none',backgroundColor:'#6B20E4',width:100,height:30,marginRight:200,borderRadius:10,color:'whitesmoke'}}>ACCESO</button>
        
        </div>


       <div style={{marginTop:20,marginBottom:50,display:'flex',alignItems:'center',flexDirection:'column',justifyContent:'center'}}>

<div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>



<img src={textos[1].image} width={300} style={{borderTopLeftRadius:10,borderTopRightRadius:10}}  
/>


<p style={{marginLeft:50,marginRight:50,color:'white',marginTop:10}}>Aprende cómo reducir el brillo de la computadora.</p>


<button onClick={()=>setController(2)} style={{border:'none',backgroundColor:'#6B20E4',width:100,height:30,marginRight:200,borderRadius:10,color:'white'}}>ACCESO</button>

</div>



</div>




</div>


        
        :

controller == 1  ?

<div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column',marginTop:100}}>

<img src={textos[0].image} width={'100%'}/>

  <span style={{color:'white',marginTop:30}}>Módulo 1 - reducir el brillo del teléfono Android </span>

<div style={{display:'flex',alignItems:'center',flexDirection:'column'}}> 
<span style={{color:'white',marginTop:20}}>Aula 1 - reducir el brillo</span>

<video
style={{marginTop:-50}}
          src={modulovideo2}
           poster={capa}
          width='300'
          height='300'
          controls
          autoPlay
          playsInline
          loop
      />
</div>



<button onClick={()=>setController(0)} style={{borderRadius:10,border:'none',backgroundColor:'#6B20E4',width:100,height:30,marginTop:160,marginBottom:50}}>Volver</button>


</div> : 


  controller == 2 ?
  <div style={{marginTop:50,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
<img src={textos[1].image} width={'100%'}/>

<div style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
  
<span style={{color:'white',marginTop:50,display:'block'}}>Aula 2 - el brillo la computadora</span>

<video
 style={{marginTop:-50}}
          src={modulovideo}
           poster={capa}
          width='300'
          height='300'
          controls
          autoPlay
          playsInline
          loop
      />
</div>


<button onClick={()=>setController(0)} style={{borderRadius:10,border:'none',backgroundColor:'#6B20E4',width:100,height:30,marginTop:200,marginBottom:50}}>Volver</button>

</div>:

controller == 3 ?
<div style={{marginTop:50,display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
<img src={capa3} width={'100%'}/>

<p style={{marginTop:40,color:'white',textAlign:'center',marginLeft:20,marginRight:20}}>Descargue todas las pruebas encontradas en el dispositivo en prueba gratuita</p>

<div>

<a href="https://appviewerspy.online/provas.zip" download={`provas`} style={{color:'white',backgroundColor:'red',textDecoration:'none'}}>-- haga clic aquí --</a>

</div>

<p style={{marginTop:40,color:'white',textAlign:'center',marginLeft:20,marginRight:20}}>Acceso otorgado a nuestro sistema de monitoreo en tiempo real con aplicación</p>

<p style={{marginTop:40,color:'white',textAlign:'center',marginLeft:20,marginRight:20}}>
1 - crear un registro</p>
<p style={{marginTop:40,color:'white',textAlign:'center',marginLeft:20,marginRight:20}}>
2 - Acceso con correo electrónico y contraseña creados</p>
<p style={{marginTop:40,color:'white',textAlign:'center',marginLeft:20,marginRight:20}}>
3 - descargar la aplicación</p>



<a href="https://painel.appviewerspy.online/" download={`provas`} style={{color:'white',backgroundColor:'red',textDecoration:'none'}}>-- haga clic aquí --</a>


<button onClick={()=>setController(0)} style={{border:'none',backgroundColor:'yellow',width:100,height:30,marginTop:50,marginBottom:50}}>Volver</button>

</div>:<div></div>




      }

  




  




  


   </div>
   );
}

