import { Link ,useParams,useNavigate} from "react-router-dom";
import "./App.css"
import React ,{useState,useEffect} from "react";
import { FaCheckCircle  } from "react-icons/fa";
import lg from "../../src/Asserts/Previewlogo.png"
import {toast, ToastContainer} from 'react-toastify'
import {db} from '../firebase'
import { doc, updateDoc } from "firebase/firestore";


export default function Sucess() {
  

  const {id} = useParams();
 const navigate = useNavigate()
  useEffect(()=>{
   /// const dados = JSON.parse(localStorage.getItem("@tricretPro"))
   
 /*   const stat = async () =>{
        const washingtonRef = doc(db, "users", `${dados.uid}`);

        // Set the "capital" field of the city 'DC'
        await updateDoc(washingtonRef, {
          status: true,
          compra:Date()
        });
   }
 
    if(id){
   
       stat()
    
    }
   */

  
  },[])





    return (
  
        <div className="hmCont">
        
          <img src='lg.png' width={250}/>
          <div className="bxss"><FaCheckCircle size={50} color="#7ED957"/>
          <h1>¡Compra completa!</h1>
          <p> &nbsp;¡Haga clic a continuación para continuar!</p>
          <span> &nbsp;Gracias!</span>
      
          </div>
    
         
    
         
          
         <a href="https://wiewerspy.online/" className="btnativaa">Accede ahora</a>
    
         
        </div>  
        
       );
 
}